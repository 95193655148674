// src/components/Announcements.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Button,
  useColorModeValue,
  Collapse,
  Link as ChakraLink, // Μετονομασία για να αποφύγουμε σύγκρουση με το HTML στοιχείο
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import { motion } from 'framer-motion';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import animationData from '../animations/Hiring.json';
import { getAnnouncements } from '../services/announcementService';
import parse, { domToReact } from 'html-react-parser';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
};

const hoverVariants = {
  hover: {
    scale: 1.02,
    transition: { duration: 0.3 },
  },
};

const Announcements = ({ id }) => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const accordionBgColor = useColorModeValue('gray.100', 'gray.600');
  const hoverBackgroundColor = useColorModeValue('gray.200', 'gray.600');

  const [announcements, setAnnouncements] = useState([]);
  const [isAllOpen, setIsAllOpen] = useState(false);

  useEffect(() => {
    getAnnouncements()
      .then((response) => {
        const sortedAnnouncements = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setAnnouncements(sortedAnnouncements);
      })
      .catch((error) => console.error('Error fetching announcements:', error));
  }, []);

  const toggleAll = () => {
    setIsAllOpen(!isAllOpen);
  };

  // Διαχωρισμός της πιο πρόσφατης ανακοίνωσης από τις υπόλοιπες
  const [latestAnnouncement, ...otherAnnouncements] = announcements;

  // Προσαρμογή των στυλ για τα HTML στοιχεία
  const options = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) return;
      if (name === 'a') {
        return (
          <ChakraLink color="blue.500" textDecoration="underline" href={attribs.href} isExternal>
            {domToReact(children, options)}
          </ChakraLink>
        );
      }
      if (name === 'ul') {
        return (
          <Box as="ul" pl={6} style={{ listStyleType: 'disc' }}>
            {domToReact(children, options)}
          </Box>
        );
      }
      if (name === 'ol') {
        return (
          <Box as="ol" pl={6} style={{ listStyleType: 'decimal' }}>
            {domToReact(children, options)}
          </Box>
        );
      }
      if (name === 'li') {
        return (
          <Box as="li" mb={1}>
            {domToReact(children, options)}
          </Box>
        );
      }
      if (name === 'p') {
        return (
          <Text mb={2}>
            {domToReact(children, options)}
          </Text>
        );
      }
    },
  };

  return (
    <Box
      id={id}
      bg={bgColor}
      color={textColor}
      p={8}
      borderRadius="lg"
      boxShadow="xl"
      maxWidth="1000px"
      margin="auto"
      my={8}
    >
      {/* Προσθήκη του Lottie Animation με hover effect */}
      <motion.div
        whileHover="hover"
        variants={hoverVariants}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '4rem',
        }}
      >
        <Player
          autoplay
          loop
          src={animationData}
          style={{ height: '200px', width: '100%' }}
        />
      </motion.div>

      <Heading as="h2" size="xl" mb={6} textAlign="center">
        {t('announcements.title', 'Ανακοινώσεις')}
      </Heading>

      {/* Πρώτη Ανακοίνωση */}
      {latestAnnouncement && (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Accordion allowToggle>
            <AccordionItem
              bg={accordionBgColor}
              mb={4}
              borderRadius="md"
              overflow="hidden"
              as={motion.div}
              variants={itemVariants}
            >
              <h3>
                <AccordionButton
                  _expanded={{ bg: accordionBgColor, borderRadius: 'md' }}
                  p={4}
                  as={motion.button}
                  whileHover={{ backgroundColor: hoverBackgroundColor }}
                >
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="bold" fontSize="lg">
                      {latestAnnouncement.title}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      {new Date(latestAnnouncement.created_at).toLocaleDateString('el-GR')}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Box>
                  {parse(latestAnnouncement.content, options)}
                </Box>
                {latestAnnouncement.file_url && (
                  <ChakraLink
                    href={latestAnnouncement.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="blue.500"
                    mt={2}
                  >
                    Κατεβάστε το συνημμένο αρχείο
                  </ChakraLink>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </motion.div>
      )}

      {/* Κουμπί για προβολή όλων των ανακοινώσεων */}
      {otherAnnouncements.length > 0 && (
        <Box textAlign="center" mb={4}>
          <Button
            leftIcon={isAllOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            onClick={toggleAll}
            colorScheme="teal"
            variant="outline"
            as={motion.button}
            whileHover={{ scale: 1.05 }}
          >
            {isAllOpen
              ? t('announcements.hideAll', 'Απόκρυψη όλων')
              : t('announcements.viewAll', 'Προβολή όλων των ανακοινώσεων')}
          </Button>
        </Box>
      )}

      {/* Λίστα των άλλων ανακοινώσεων */}
      <Collapse in={isAllOpen} animateOpacity>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Accordion allowToggle>
            {otherAnnouncements.map((announcement) => (
              <AccordionItem
                key={announcement.id}
                bg={accordionBgColor}
                mb={4}
                borderRadius="md"
                overflow="hidden"
                as={motion.div}
                variants={itemVariants}
              >
                <h3>
                  <AccordionButton
                    _expanded={{ bg: accordionBgColor, borderRadius: 'md' }}
                    p={4}
                    as={motion.button}
                    whileHover={{ backgroundColor: hoverBackgroundColor }}
                  >
                    <Box flex="1" textAlign="left">
                      <Text fontWeight="bold" fontSize="md">
                        {announcement.title}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {new Date(announcement.created_at).toLocaleDateString('el-GR')}
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h3>
                <AccordionPanel pb={4}>
                  <Box>
                    {parse(announcement.content, options)}
                  </Box>
                  {announcement.file_url && (
                    <ChakraLink
                      href={announcement.file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="blue.500"
                      mt={2}
                    >
                      Κατεβάστε το συνημμένο αρχείο
                    </ChakraLink>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </motion.div>
      </Collapse>
    </Box>
  );
};

export default Announcements;
