// frontend/src/services/userService.js

import axiosInstance from './axiosInstance';

// Login Χρήστη
export const loginUser = (credentials) => {
  return axiosInstance.post('/auth/login', credentials);
};

// Εγγραφή Νέου Χρήστη (μόνο admin)
export const registerUser = (userData) => {
  return axiosInstance.post('/auth/register', userData);
};

// Λήψη Όλων των Χρηστών (μόνο admin)
export const getUsers = () => {
  return axiosInstance.get('/auth/users');
};

// Διαγραφή Χρήστη (μόνο admin)
export const deleteUser = (id) => {
  return axiosInstance.delete(`/auth/users/${id}`);
};
