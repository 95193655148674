import React from 'react';
import { Box, Heading, Text, Flex, useColorModeValue } from '@chakra-ui/react'; // Add the missing Chakra UI imports
import { useTranslation } from 'react-i18next'; // Import for translations
import styles from './CouncilSection.module.css'; // Assuming the CSS module is correct

const CouncilSection = ({ id }) => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.600');
  const titleColor = useColorModeValue('blue.500', 'blue.300');

  const councilMembers = [
    { name: 'Δρ. Χρίστος Γεωργιάδης', title: 'ΠΡΟΕΔΡΟΣ' },
    { name: 'Δρ. Ανδρέας Χριστοδούλου', title: 'ΑΝΤΙΠΡΟΕΔΡΟΣ' },
    { name: 'Δρ. Άνδρη Ευρυπίδου', title: 'ΓΡΑΜΜΑΤΕΑΣ' },
    { name: 'Δρ. Ιλιάδα Ευριπίδου', title: 'ΤΑΜΙΑΣ' },
    { name: 'Δρ. Φίλιππος Κωνσταντίνου', title: 'ΜΕΛΟΣ' },
    { name: 'Δρ. Σπυριδάκης Χρυσοστόμου', title: 'ΜΕΛΟΣ' },
    { name: 'Δρ. Χάρης Κοντός', title: 'ΜΕΛΟΣ' },
  ];

  return (
    <Box data-aos="fade-up" id={id} className={styles.councilSection} bg={bgColor} color={textColor} py={12}>
      <Heading as="h2" size="xl" textAlign="center" mb={12}>
        {t('council.title')}
      </Heading>
      <Flex flexWrap="wrap" justifyContent="center" maxWidth="1200px" margin="auto" px={4}>
        {councilMembers.map((member, index) => (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={6}
            className={styles.memberCard}
            bg={cardBgColor}
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
            width={["100%", "45%", "30%", "22%"]}
            margin={2}
          >
            <Text fontWeight="bold" mb={3} color={titleColor} fontSize="lg">
              {member.title}
            </Text>
            <Heading as="h3" size="md" mb={3} color={textColor}>
              {member.name}
            </Heading>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default CouncilSection;