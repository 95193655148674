import React from 'react';
import { Box, Heading, Text, SimpleGrid, useColorModeValue, VStack, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaHistory, FaBullseye, FaEye, FaHandshake } from 'react-icons/fa';
import { Player } from '@lottiefiles/react-lottie-player'; // Εισαγωγή για το Lottie Player
import { motion } from 'framer-motion'; // Εισαγωγή του Framer Motion
import animationData from '../../animations/Medication.json'; // Σωστή διαδρομή
import styles from './AboutSection.module.css'; 

// Δημιουργία animation για κάθε στοιχείο
const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.6 }
  }
};

// Δημιουργία staggered animation
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3, // Καθυστέρηση μεταξύ των αντικειμένων
    }
  }
};

const AboutSection = ({ id }) => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const boxBgColor = useColorModeValue('white', 'gray.600');

  const aboutContent = [
    { title: 'about.history.title', content: 'about.history.content', icon: FaHistory },
    { title: 'about.mission.title', content: 'about.mission.content', icon: FaBullseye },
    { title: 'about.vision.title', content: 'about.vision.content', icon: FaEye },
    { title: 'about.values.title', content: 'about.values.content', icon: FaHandshake },
  ];

  return (
    <Box data-aos="fade-up" id={id} className={styles.aboutSection} bg={bgColor} color={textColor} py={12}>
      <Box maxWidth="1200px" margin="auto" px={4}>
        {/* Προσθήκη του Lottie Animation */}
        <motion.div initial={{ scale: 0.8, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 1 }}>
          <Box mb={6} display="flex" justifyContent="center">
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: '300px', width: '300px' }}
            />
          </Box>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={containerVariants}
          viewport={{ once: true }} // Κάνει το animation να παίζει μόνο μία φορά
        >
          <Heading as="h2" size="xl" textAlign="center" mb={12}>
            {t('about.title')}
          </Heading>

          <SimpleGrid columns={[1, null, 2]} spacing={8}>
            {aboutContent.map((item, index) => (
              <motion.div key={index} variants={itemVariants}>
                <Box
                  className={styles.aboutBox}
                  bg={boxBgColor}
                  p={6}
                  borderRadius="lg"
                  boxShadow="md"
                >
                  <VStack align="start" spacing={4}>
                    <Icon as={item.icon} boxSize={8} color="blue.500" />
                    <Heading as="h3" size="lg">
                      {t(item.title)}
                    </Heading>
                    <Text textAlign="justify">{t(item.content)}</Text> {/* Προσθήκη textAlign */}
                  </VStack>
                </Box>
              </motion.div>
            ))}
          </SimpleGrid>
        </motion.div>
      </Box>
    </Box>
  );
};

export default AboutSection;
