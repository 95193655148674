// frontend/src/components/LoginForm.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/userService';
import { AuthContext } from '../context/AuthContext';
import { Box, Input, Button, Heading, Text } from '@chakra-ui/react';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit called');
    
    try {
      console.log('Before calling loginUser');
      console.log('Credentials:', { username, password });
      const response = await loginUser({ username, password });
      console.log('After calling loginUser');
      console.log('Server response:', response.data);
      login(response.data);
      navigate('/');
    } catch (err) {
      console.error('Login failed:', err.response || err);
      console.log('Error details:', err);
      setError('Αποτυχία σύνδεσης. Ελέγξτε τα στοιχεία σας.');
    }
  };
   
  
  return (
    <Box maxWidth="400px" margin="auto" py={12} px={4}>
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        Σύνδεση
      </Heading>
      {error && <Text color="red.500" mb={4}>{error}</Text>}
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Όνομα Χρήστη"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          mb={4}
          required
        />
        <Input
          placeholder="Κωδικός"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb={4}
          required
        />
        <Button type="submit" colorScheme="blue" width="full">
          Σύνδεση
        </Button>
      </form>
    </Box>
  );
};

export default LoginForm;
