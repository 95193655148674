// frontend/src/components/ArticlesSection/ArticlesSection.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Grid,
  Link,
  Avatar,
  VStack,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styles from './ArticlesSection.module.css';
import { getArticles } from '../../services/articleService';
import { Link as RouterLink } from 'react-router-dom';

const ArticlesSection = ({ id }) => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const cardBgColor = useColorModeValue('white', 'gray.600');

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getArticles()
      .then((response) => setArticles(response.data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <Box
      data-aos="fade-up"
      id={id}
      className={styles.articlesSection}
      bg={bgColor}
      color={textColor}
      py={12}
    >
      <Heading as="h2" size="xl" textAlign="center" mb={12}>
        {t('articles.title')}
      </Heading>
      <Grid
        templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
        gap={8}
        maxWidth="1200px"
        margin="auto"
        px={4}
      >
        {articles.map((article) => (
          <Box
            key={article.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={6}
            bg={cardBgColor}
            className={styles.articleCard}
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
          >
            <VStack align="start" spacing={4}>
              <Heading as="h3" size="lg" mb={2}>
                {article.title}
              </Heading>
              <HStack spacing={2}>
                <Avatar
                  size="sm"
                  name={article.author}
                  src={article.author_avatar || undefined}
                />
                <Text fontSize="sm" fontWeight="medium">
                  {article.author}
                </Text>
              </HStack>
              <Text className={styles.excerpt}>{article.excerpt}</Text>
              <Link
                as={RouterLink}
                to={`/article/${article.slug}`}
                color="blue.500"
                fontWeight="semibold"
              >
                {t('articles.readMore')}
              </Link>
            </VStack>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default ArticlesSection;
