import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createArticle, getArticles, updateArticle, deleteArticle } from '../../services/articleService';

const ArticlesDashboard = () => {
  const [articles, setArticles] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    author: '',
    author_avatar: '',
    excerpt: ''
  });
  const [editingArticle, setEditingArticle] = useState(null);
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const quillRef = useRef();

  useEffect(() => {
    if (quillRef?.current) {
      const editor = quillRef.current.getEditor();
      const editorContainer = editor.container;

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            // Silently handle DOM changes
          }
        });
      });

      observer.observe(editorContainer, {
        childList: true,
        subtree: true
      });

      return () => observer.disconnect();
    }
  }, [quillRef?.current]);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await getArticles();
      setArticles(response.data);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setFormData(prev => ({ ...prev, content }));
  };

  const handleCreateOrUpdate = async () => {
    try {
      if (editingArticle) {
        await updateArticle(editingArticle.id, formData);
      } else {
        await createArticle(formData);
      }
      setFormData({ title: '', content: '', author: '', author_avatar: '', excerpt: '' });
      setEditingArticle(null);
      fetchArticles();
    } catch (error) {
      console.error('Error creating/updating article:', error);
    }
  };

  const handleEdit = (article) => {
    setEditingArticle(article);
    setFormData({
      title: article.title,
      content: article.content,
      author: article.author,
      author_avatar: article.author_avatar,
      excerpt: article.excerpt
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteArticle(id);
      fetchArticles();
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };

  return (
    <Box bg={bgColor} color={textColor} p={8} borderRadius="lg" boxShadow="xl" maxWidth="800px" margin="auto" my={8}>
      <Heading as="h2" size="xl" mb={6} textAlign="center">
        Διαχείριση Άρθρων
      </Heading>

      <Box mb={8}>
        <Heading as="h3" size="md" mb={4}>
          {editingArticle ? 'Επεξεργασία Άρθρου' : 'Νέο Άρθρο'}
        </Heading>
        <FormControl mb={4}>
          <FormLabel>Τίτλος</FormLabel>
          <Input
            name="title"
            placeholder="Τίτλος"
            value={formData.title}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Συγγραφέας</FormLabel>
          <Input
            name="author"
            placeholder="Συγγραφέας"
            value={formData.author}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Avatar URL Συγγραφέα</FormLabel>
          <Input
            name="author_avatar"
            placeholder="URL εικόνας συγγραφέα"
            value={formData.author_avatar}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Περίληψη</FormLabel>
          <Input
            name="excerpt"
            placeholder="Σύντομη περίληψη"
            value={formData.excerpt}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Περιεχόμενο</FormLabel>
          <ReactQuill
            ref={quillRef}
            value={formData.content}
            onChange={handleContentChange}
            theme="snow"
            placeholder="Περιεχόμενο"
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleCreateOrUpdate} mr={2}>
          {editingArticle ? 'Αποθήκευση' : 'Δημιουργία'}
        </Button>
        {editingArticle && (
          <Button onClick={() => {
            setEditingArticle(null);
            setFormData({ title: '', content: '', author: '', author_avatar: '', excerpt: '' });
          }}>
            Ακύρωση
          </Button>
        )}
      </Box>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Τίτλος</Th>
            <Th>Συγγραφέας</Th>
            <Th>Ενέργειες</Th>
          </Tr>
        </Thead>
        <Tbody>
          {articles.map((article) => (
            <Tr key={article.id}>
              <Td>{article.title}</Td>
              <Td>{article.author}</Td>
              <Td>
                <Button size="sm" onClick={() => handleEdit(article)} mr={2}>
                  Επεξεργασία
                </Button>
                <Button size="sm" colorScheme="red" onClick={() => handleDelete(article.id)}>
                  Διαγραφή
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ArticlesDashboard;
