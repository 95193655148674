// src/services/articleService.js
import axiosInstance from './axiosInstance';

// Δημιουργία Άρθρου
export const createArticle = (articleData) =>
  axiosInstance.post(`/articles`, articleData);

// Λήψη Όλων των Άρθρων
export const getArticles = () =>
  axiosInstance.get(`/articles`);

// Λήψη Άρθρου με βάση το ID
export const getArticleById = (id) =>
  axiosInstance.get(`/articles/${id}`);

// Λήψη Άρθρου με βάση το Slug
export const getArticleBySlug = (slug) =>
  axiosInstance.get(`/articles/slug/${slug}`);

// Ενημέρωση Άρθρου
export const updateArticle = (id, articleData) =>
  axiosInstance.put(`/articles/${id}`, articleData);

// Διαγραφή Άρθρου
export const deleteArticle = (id) =>
  axiosInstance.delete(`/articles/${id}`);
