import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createAnnouncement, getAnnouncements, updateAnnouncement, deleteAnnouncement } from '../services/announcementService';

const AnnouncementsDashboard = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [formData, setFormData] = useState({ title: '', content: '', file: null });
  const [editingAnnouncement, setEditingAnnouncement] = useState(null);
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const quillRef = useRef();

  // Add MutationObserver to handle DOM mutations silently
  useEffect(() => {
    if (quillRef?.current) {
      const editor = quillRef.current.getEditor();
      const editorContainer = editor.container;

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            // Silently handle DOM changes
          }
        });
      });

      observer.observe(editorContainer, {
        childList: true,
        subtree: true
      });

      return () => observer.disconnect();
    }
  }, [quillRef?.current]);

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const response = await getAnnouncements();
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      setFormData(prev => ({ ...prev, file: files[0] }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleContentChange = (content) => {
    setFormData(prev => ({ ...prev, content }));
  };

  const handleCreateOrUpdate = async () => {
    try {
      if (editingAnnouncement) {
        await updateAnnouncement(editingAnnouncement.id, formData);
      } else {
        await createAnnouncement(formData);
      }
      setFormData({ title: '', content: '', file: null });
      setEditingAnnouncement(null);
      fetchAnnouncements();
    } catch (error) {
      console.error('Error creating/updating announcement:', error);
    }
  };

  const handleEdit = (announcement) => {
    setEditingAnnouncement(announcement);
    setFormData({
      title: announcement.title,
      content: announcement.content,
      file: null,
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteAnnouncement(id);
      fetchAnnouncements();
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  return (
    <Box bg={bgColor} color={textColor} p={8} borderRadius="lg" boxShadow="xl" maxWidth="800px" margin="auto" my={8}>
      <Heading as="h2" size="xl" mb={6} textAlign="center">
        Διαχείριση Ανακοινώσεων
      </Heading>

      <Box mb={8}>
        <Heading as="h3" size="md" mb={4}>
          {editingAnnouncement ? 'Επεξεργασία Ανακοίνωσης' : 'Νέα Ανακοίνωση'}
        </Heading>
        <FormControl mb={4}>
          <FormLabel>Τίτλος</FormLabel>
          <Input
            name="title"
            placeholder="Τίτλος"
            value={formData.title}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Περιεχόμενο</FormLabel>
          <ReactQuill
            ref={quillRef}
            value={formData.content}
            onChange={handleContentChange}
            theme="snow"
            placeholder="Περιεχόμενο"
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Συνημμένο Αρχείο (προαιρετικό)</FormLabel>
          <Input
            type="file"
            name="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
            onChange={handleInputChange}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleCreateOrUpdate} mr={2}>
          {editingAnnouncement ? 'Αποθήκευση' : 'Δημιουργία'}
        </Button>
        {editingAnnouncement && (
          <Button onClick={() => { setEditingAnnouncement(null); setFormData({ title: '', content: '', file: null }); }}>
            Ακύρωση
          </Button>
        )}
      </Box>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Τίτλος</Th>
            <Th>Ημερομηνία</Th>
            <Th>Ενέργειες</Th>
          </Tr>
        </Thead>
        <Tbody>
          {announcements.map((announcement) => (
            <Tr key={announcement.id}>
              <Td>{announcement.title}</Td>
              <Td>{new Date(announcement.created_at).toLocaleDateString('el-GR')}</Td>
              <Td>
                <Button size="sm" onClick={() => handleEdit(announcement)} mr={2}>
                  Επεξεργασία
                </Button>
                <Button size="sm" colorScheme="red" onClick={() => handleDelete(announcement.id)}>
                  Διαγραφή
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default AnnouncementsDashboard;
