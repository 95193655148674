import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, useColorModeValue, Heading, Text, useToast, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';
import { CheckCircleIcon } from '@chakra-ui/icons';

const MotionBox = motion.create(Box);

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    registrationNumber: '',
    email: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const hoverBorderColor = useColorModeValue('gray.400', 'gray.500');
  const focusBorderColor = 'blue.400';
  const asteriskColor = useColorModeValue('red.500', 'red.300');
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    // Αποστολή email στον αιτούντα και στον διαχειριστή
    const emailToApplicant = {
      to_name: formData.name,
      to_email: formData.email,
    };
    const emailToAdmin = {
      from_name: formData.name,
      from_email: formData.email,
      phone: formData.phone,
      registration_number: formData.registrationNumber,
    };

    // Αποστολή ευχαριστηρίου email στον αιτούντα
    emailjs.send('service_18yzxhl', 'template_t0fdqzt', emailToApplicant, 'JfCtqhn3vXQeS7kPR');
    // Αποστολή email στον διαχειριστή
    emailjs.send('service_18yzxhl', 'template_g7bwwde', emailToAdmin, 'JfCtqhn3vXQeS7kPR');

    toast({
      title: 'Η αίτηση εστάλη επιτυχώς!',
      description: 'Θα επικοινωνήσουμε μαζί σας σύντομα.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    setTimeout(() => {
      setIsSubmitted(false);
      setFormData({ name: '', phone: '', registrationNumber: '', email: '' });
    }, 3000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Box bg={bgColor} color={textColor} borderRadius="lg" p={8} shadow="xl" width="100%" maxWidth="600px" margin="auto">
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        {t('registration.title', 'Εγγραφή')}
      </Heading>

      {isSubmitted ? (
        <MotionBox
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CheckCircleIcon boxSize={20} color="green.500" mb={4} />
          <Text fontSize="2xl" mt={2} textAlign="center">
            Η αίτησή σας εστάλη επιτυχώς!
          </Text>
        </MotionBox>
      ) : (
        <form onSubmit={handleSubmit}>
          <VStack spacing={6}>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('registration.form.name', 'Όνομα')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder={t('registration.form.namePlaceholder', 'Συμπληρώστε το όνομά σας')}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('registration.form.phone', 'Τηλέφωνο')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder={t('registration.form.phonePlaceholder', 'Συμπληρώστε το τηλέφωνό σας')}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('registration.form.registrationNumber', 'Αριθμός Μητρώου')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Input
                type="text"
                name="registrationNumber"
                value={formData.registrationNumber}
                onChange={handleInputChange}
                placeholder={t('registration.form.registrationNumberPlaceholder', 'Συμπληρώστε τον αριθμό μητρώου')}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('registration.form.email', 'Email')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder={t('registration.form.emailPlaceholder', 'Συμπληρώστε το email σας')}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              width="full"
              size="lg"
              fontWeight="bold"
              boxShadow="md"
              _active={{ bg: 'blue.700', transform: 'scale(0.98)' }}
              transition="all 0.2s"
            >
              {t('registration.form.submit', 'Υποβολή')}
            </Button>
          </VStack>
        </form>
      )}
    </Box>
  );
};

export default RegistrationForm;