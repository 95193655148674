import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  useColorModeValue,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player'; // Εισαγωγή του Lottie Player
import animationData from '../animations/HospitalReception.json';

const MotionBox = motion.create(Box);

const ContactForm = ({ id }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const hoverBorderColor = useColorModeValue('gray.400', 'gray.500');
  const focusBorderColor = 'blue.400';
  const asteriskColor = useColorModeValue('red.500', 'red.300');
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    const emailToSender = {
      from_name: 'Ιατρικός Σύλλογος Πάφου',
      to_email: formData.email,
      to_name: formData.name,
    };

    const emailToAdmin = {
      from_name: formData.name,
      from_email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    emailjs
      .send(
        'service_18yzxhl',
        'template_stmh75p',
        emailToSender,
        'JfCtqhn3vXQeS7kPR'
      )
      .then(
        (result) => {
          console.log('Email to sender sent successfully:', result.text);
        },
        (error) => {
          console.log('Error in sending email to sender:', error.text);
        }
      );

    emailjs
      .send(
        'service_18yzxhl',
        'template_966bzlo',
        emailToAdmin,
        'JfCtqhn3vXQeS7kPR'
      )
      .then(
        (result) => {
          console.log('Email to admin sent successfully:', result.text);
          toast({
            title: 'Το μήνυμα εστάλη επιτυχώς!',
            description: 'Θα επικοινωνήσουμε μαζί σας σύντομα.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });

          setTimeout(() => {
            setIsSubmitted(false);
            setFormData({ name: '', email: '', subject: '', message: '' });
          }, 3000);
        },
        (error) => {
          console.log('Error in sending email to admin:', error.text);
          toast({
            title: 'Παρουσιάστηκε κάποιο σφάλμα.',
            description: 'Παρακαλούμε δοκιμάστε ξανά.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Box
      id={id}
      bg={bgColor}
      color={textColor}
      borderRadius="lg"
      p={8}
      shadow="xl"
      width="100%"
      maxWidth="600px"
      margin="auto"
      textAlign="center"
    >
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        {t('contact.title')}
      </Heading>

      {isSubmitted ? (
        <MotionBox
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <Player
            autoplay
            loop={false}
            src={animationData}
            style={{ height: '300px', width: '300px' }}
          />
          <Text fontSize="2xl" mt={4}>
            Το μήνυμά σας εστάλη επιτυχώς!
          </Text>
        </MotionBox>
      ) : (
        <form onSubmit={handleSubmit}>
          <VStack spacing={6}>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('contact.form.name')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder={t('contact.form.namePlaceholder')}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('contact.form.email')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder={t('contact.form.emailPlaceholder')}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('contact.form.subject')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                placeholder={t('contact.form.subjectPlaceholder')}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">
                {t('contact.form.message')}
                <Text as="span" color={asteriskColor} ml={1}>*</Text>
              </FormLabel>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder={t('contact.form.messagePlaceholder')}
                rows={6}
                borderColor={borderColor}
                _hover={{ borderColor: hoverBorderColor }}
                _focus={{ borderColor: focusBorderColor, boxShadow: `0 0 0 1px ${focusBorderColor}` }}
                transition="all 0.2s"
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              width="full"
              size="lg"
              fontWeight="bold"
              boxShadow="md"
              _active={{ bg: 'blue.700', transform: 'scale(0.98)' }}
              transition="all 0.2s"
            >
              {t('contact.form.send')}
            </Button>
          </VStack>
        </form>
      )}
    </Box>
  );
};

export default ContactForm;
