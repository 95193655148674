// src/App.js

import React, { useEffect } from 'react';
import { ChakraProvider, Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Components
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import AboutSection from './components/AboutSection/AboutSection';
import CouncilSection from './components/CouncilSection/CouncilSection';
import Announcements from './components/Announcements';
import ArticlesSection from './components/ArticlesSection/ArticlesSection';
import ContactForm from './components/ContactForm';
import RegistrationForm from './components/RegistrationForm';
import Footer from './components/Footer';
import NewArticleForm from './components/NewArticleForm';
import ArticleDetails from './components/ArticleDetails';
import EditArticleForm from './components/EditArticleForm';
import LoginForm from './components/LoginForm';
import ManageUsers from './components/ManageUsers';
import AnnouncementsDashboard from './components/AnnouncementsDashboard';
import ArticlesDashboard from './components/ArticlesDashboard/ArticlesDashboard';
import PrivateRoute from './components/PrivateRoute';

// Context
import { AuthProvider } from './context/AuthContext';

function App() {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <ChakraProvider>
      <AuthProvider>
        <Router>
          <Box
            className="App"
            bg={bgColor}
            color={textColor}
            minHeight="100vh"
            display="flex"
            flexDirection="column"
          >
            <Header />
            <Box pt="60px" flex="1">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Box>
                      <LandingPage />
                      <Flex
                        direction="column"
                        align="center"
                        maxWidth="1200px"
                        margin="auto"
                        p={8}
                      >
                        <AboutSection id="about" />
                        <CouncilSection id="council" />
                        <Announcements id="announcements" />
                        <ArticlesSection id="articles" />
                        <ContactForm id="contact" />
                      </Flex>
                    </Box>
                  }
                />
                <Route path="/login" element={<LoginForm />} />
                <Route
                  path="/register"
                  element={
                    <PrivateRoute adminOnly={true}>
                      <RegistrationForm />
                    </PrivateRoute>
                  }
                />
                {/* Προστατευμένες routes για admin */}
                <Route
                  path="/new-article"
                  element={
                    <PrivateRoute adminOnly={true}>
                      <NewArticleForm />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/edit-article/:id"
                  element={
                    <PrivateRoute adminOnly={true}>
                      <EditArticleForm />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/manage-users"
                  element={
                    <PrivateRoute adminOnly={true}>
                      <ManageUsers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/manage-announcements"
                  element={
                    <PrivateRoute adminOnly={true}>
                      <AnnouncementsDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/articles"
                  element={
                    <PrivateRoute adminOnly={true}>
                      <ArticlesDashboard />
                    </PrivateRoute>
                  }
                />
                {/* Άλλα routes */}
                <Route path="/article/:slug" element={<ArticleDetails />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
