// frontend/src/components/ArticleDetails.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getArticleBySlug, deleteArticle } from '../services/articleService';
import {
  Box,
  Heading,
  Text,
  Avatar,
  HStack,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { AuthContext } from '../context/AuthContext';

const ArticleDetails = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getArticleBySlug(slug)
      .then((response) => setArticle(response.data))
      .catch((error) => console.error(error));
  }, [slug]);

  const handleDelete = () => {
    if (window.confirm('Είσαι σίγουρος ότι θέλεις να διαγράψεις αυτό το άρθρο;')) {
      deleteArticle(article.id)
        .then(() => {
          alert('Το άρθρο διαγράφηκε επιτυχώς');
          navigate('/');
        })
        .catch((error) => console.error(error));
    }
  };

  if (!article) {
    return <Text>Φόρτωση...</Text>;
  }

  const cleanContent = DOMPurify.sanitize(article.content);

  return (
    <Box bg={bgColor} color={textColor} py={12} maxWidth="800px" margin="auto" px={4}>
      <Heading as="h1" size="2xl" mb={4}>
        {article.title}
      </Heading>
      <HStack spacing={2} mb={6}>
        <Avatar
          size="sm"
          name={article.author}
          src={article.author_avatar || undefined}
        />
        <Text fontSize="sm" fontWeight="medium">
          {article.author}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {new Date(article.publication_date).toLocaleDateString()}
        </Text>
      </HStack>
      <Box fontSize="lg" dangerouslySetInnerHTML={{ __html: cleanContent }} />
      {user && user.role === 'admin' && (
        <HStack spacing={4} mt={6}>
          <Button colorScheme="blue" onClick={() => navigate(`/edit-article/${article.id}`)}>
            Επεξεργασία
          </Button>
          <Button colorScheme="red" onClick={handleDelete}>
            Διαγραφή
          </Button>
        </HStack>
      )}
    </Box>
  );
};

export default ArticleDetails;
