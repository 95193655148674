import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  HStack,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon, LockIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logoutUser } = useContext(AuthContext);

  const [scrollPosition, setScrollPosition] = useState(0);

  const clubPrimaryColor = '#0056B3';
  const lottieBgColor = '#EAF4FB';

  const bgColor = scrollPosition > 500 ? 'white' : lottieBgColor;
  const linkColor = clubPrimaryColor;

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 70;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      window.history.pushState(null, null, `#${id}`);
    }
  };

  const handleMenuClick = (to) => {
    navigate(to);
  };

  const publicMenuItems = [
    { key: 'home', label: 'Αρχική', to: 'home' },
    { key: 'about', label: 'Σχετικά', to: 'about' },
    { key: 'council', label: 'Συμβούλιο', to: 'council' },
    { key: 'announcements', label: 'Ανακοινώσεις', to: 'announcements' },
    { key: 'articles', label: 'Άρθρα', to: 'articles' },
    { key: 'contact', label: 'Επικοινωνία', to: 'contact' },
  ];

  const adminMenuItems = [
    { key: 'manageAnnouncements', label: 'Διαχείριση Ανακοινώσεων', to: '/manage-announcements' },
    { key: 'createArticle', label: 'Δημιουργία Άρθρου', to: '/new-article' },
    { key: 'manageUsers', label: 'Διαχείριση Χρηστών', to: '/manage-users' },
  ];

  const menuItems = user && user.role === 'admin' ? adminMenuItems : publicMenuItems;

  const linkButtonStyle = {
    bg: 'transparent',
    color: linkColor,
    variant: 'link',
    _hover: {
      textDecoration: 'underline',
      color: linkColor,
    },
    _active: {
      color: linkColor,
    },
    fontSize: { base: 'md', md: 'lg', lg: 'xl' }, // Αυξάνουμε το μέγεθος της γραμματοσειράς
    letterSpacing: { base: 'normal', md: 'wide' }, // Αυξάνουμε την απόσταση των γραμμάτων
    mr: 0, // Μηδενίζουμε το margin δεξιά
  };

  return (
    <Box
      as="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={9999}
      bg={bgColor}
      transition="background-color 0.3s ease, color 0.3s ease"
      boxShadow="md"
    >
      <Flex
        maxW="container.xl"
        mx="auto"
        px={4}
        py={2}
        alignItems="center"
        justifyContent="space-between"
        height="60px"
      >
        {/* Λογότυπο */}
        <Box flexShrink={0}>
          <Image
            src="/images/asclepius.png"
            alt="Ιατρικός Σύλλογος Πάφου"
            height="50px"
            objectFit="contain"
          />
        </Box>

        {/* Μενού Links (Desktop Only) */}
        <Flex
          flex="1"
          justifyContent="center"
          alignItems="center"
          display={{ base: 'none', md: 'flex' }}
        >
          <HStack spacing={{ base: 2, md: 4, lg: 6 }}>
            {menuItems.map((item) => (
              <Button
                key={item.key}
                as={RouterLink}
                to={item.to.startsWith('/') ? item.to : `#${item.to}`}
                {...linkButtonStyle}
                onClick={() => {
                  if (!item.to.startsWith('/')) {
                    handleScrollTo(item.to);
                  }
                }}
              >
                {item.label}
              </Button>
            ))}
          </HStack>
        </Flex>

        {/* Στοιχεία δεξιάς πλευράς (Desktop Only) */}
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          {!user ? (
            <IconButton
              as={RouterLink}
              to="/login"
              icon={<LockIcon />}
              aria-label="Login"
              {...linkButtonStyle}
            />
          ) : (
            <HStack spacing={2} mr={2}>
              <Avatar size="sm" name={user.username} />
              <Button onClick={logoutUser} {...linkButtonStyle}>
                Logout
              </Button>
            </HStack>
          )}
        </Flex>

        {/* Mobile Menu */}
        <Box display={{ base: 'block', md: 'none' }}>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<HamburgerIcon />}
              variant="outline"
              aria-label="Open Menu"
            />
            <MenuList>
              {menuItems.map((item) => (
                <MenuItem
                  key={item.key}
                  as={RouterLink}
                  to={item.to.startsWith('/') ? item.to : `#${item.to}`}
                  onClick={() => {
                    if (!item.to.startsWith('/')) {
                      handleScrollTo(item.to);
                    }
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}

              {!user ? (
                <MenuItem as={RouterLink} to="/login">
                  Login
                </MenuItem>
              ) : (
                <>
                  <MenuItem onClick={logoutUser}>Logout</MenuItem>
                  <MenuItem>
                    <HStack>
                      <Avatar size="sm" name={user.username} />
                      <Text ml={2}>{user.username}</Text>
                    </HStack>
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
