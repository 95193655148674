// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => {
    const tokenString = localStorage.getItem('authTokens');
    return tokenString ? JSON.parse(tokenString) : null;
  });
  const [user, setUser] = useState(() => {
    return authTokens ? jwt_decode(authTokens.token) : null;
  });

  const login = (token) => {
    setAuthTokens(token);
    setUser(jwt_decode(token.token));
    localStorage.setItem('authTokens', JSON.stringify(token));
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('authTokens');
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.token));
    }
  }, [authTokens]);

  return (
    <AuthContext.Provider value={{ user, authTokens, login, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};
