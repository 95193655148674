// src/services/announcementService.js

import axiosInstance from './axiosInstance';

// Δημιουργία Ανακοίνωσης
export const createAnnouncement = (announcementData) =>
  axiosInstance.post('/announcements', announcementData);

// Λήψη Όλων των Ανακοινώσεων
export const getAnnouncements = () =>
  axiosInstance.get('/announcements');

// Λήψη Ανακοίνωσης με βάση το ID
export const getAnnouncementById = (id) =>
  axiosInstance.get(`/announcements/${id}`);

// Ενημέρωση Ανακοίνωσης
// Ενημέρωση Ανακοίνωσης
export const updateAnnouncement = (id, announcementData) =>
  axiosInstance.put(`/announcements/${id}`, announcementData);


// Διαγραφή Ανακοίνωσης
export const deleteAnnouncement = (id) =>
  axiosInstance.delete(`/announcements/${id}`);
