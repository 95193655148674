// src/services/axiosInstance.js

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/api',
  // Αφαιρούμε το default Content-Type header
});

// Προσθήκη του JWT token σε κάθε αίτημα
axiosInstance.interceptors.request.use(
  (config) => {
    const tokenString = localStorage.getItem('authTokens');
    if (tokenString) {
      const token = JSON.parse(tokenString).token;
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
