// frontend/src/components/ManageUsers.js

import React, { useEffect, useState } from 'react';
import { getUsers, deleteUser } from '../services/userService';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Text, Heading } from '@chakra-ui/react';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response.data.users);
      } catch (err) {
        console.error(err);
        setError('Failed to fetch users.');
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Είσαι σίγουρος ότι θέλεις να διαγράψεις αυτόν τον χρήστη;')) {
      try {
        await deleteUser(id);
        setUsers(users.filter(user => user.id !== id));
      } catch (err) {
        console.error(err);
        setError('Failed to delete user.');
      }
    }
  };

  return (
    <Box maxWidth="800px" margin="auto" py={12} px={4}>
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        Διαχείριση Χρηστών
      </Heading>
      {error && <Text color="red.500" mb={4}>{error}</Text>}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Όνομα Χρήστη</Th>
            <Th>Ρόλος</Th>
            <Th>Ενέργειες</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map(user => (
            <Tr key={user.id}>
              <Td>{user.id}</Td>
              <Td>{user.username}</Td>
              <Td>{user.role}</Td>
              <Td>
                <Button colorScheme="red" size="sm" onClick={() => handleDelete(user.id)}>
                  Διαγραφή
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ManageUsers;
