import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { motion } from 'framer-motion'; // Import Framer Motion
import animationData from '../animations/DoctorsDiscussing.json'; // Import Lottie JSON

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const textVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    backgroundColor: "#3182ce",
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

const LandingPage = () => {
  return (
    <Box bg="blue.50" p={[4, 6, 8]} minH="100vh" display="flex" alignItems="center" justifyContent="center">
      <Flex
        direction={["column", "column", "row"]} // Η εικόνα θα εμφανίζεται πάνω σε κινητό
        align="center"
        justify="space-between"
        maxW="1200px"
        w="100%"
      >
        {/* Δεξί τμήμα: Lottie Animation με Parallax */}
        <motion.div
          style={{ maxWidth: ["100%", "100%", "600px"], padding: "16px" }}
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <Player
            autoplay
            loop
            src={animationData}
            style={{ height: ['250px', '400px', '500px'], width: ['100%', '100%', '220%'] }}
          />
        </motion.div>

        {/* Αριστερό τμήμα: Κείμενο με staggered animations */}
        <motion.div
          initial="hidden"
          animate="show"
          variants={container}
          style={{ maxWidth: "600px", padding: "16px" }}
        >
          <motion.div variants={textVariants}>
            <Heading as="h1" size="2xl" mb={4} color="blue.800">
              Φροντίζοντας για το μέλλον της υγείας
            </Heading>
          </motion.div>
          <motion.div variants={textVariants}>
            <Text fontSize="lg" mb={6} color="gray.600">
              Η αποστολή μας είναι η προώθηση της ιατρικής αριστείας και της φροντίδας στους κατοίκους της Πάφου.
            </Text>
          </motion.div>
          <motion.div variants={textVariants}>
            <motion.div variants={buttonVariants} whileHover="hover">
              <Button
                colorScheme="blue"
                size="lg"
                onClick={() => {
                  document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Μάθετε περισσότερα
              </Button>
            </motion.div>
          </motion.div>
        </motion.div>
      </Flex>
    </Box>
  );
};

export default LandingPage;
