import React from 'react';
import { Box, Container, SimpleGrid, Stack, Text, Link, useColorModeValue, Icon, Heading, Flex } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaLinkedin, FaPhone, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const headingColor = useColorModeValue('blue.600', 'blue.300');
  const linkHoverColor = '#0056B3'; // Μπλε χρώμα για το hover στα links
  const iconHoverColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <Box
      bg={bgColor}
      color={textColor}
      py={10}
    >
      <Container maxW={'6xl'}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          <Stack align={'flex-start'}>
            <Heading as="h4" size="md" color={headingColor} mb={4}>
              {t('footer.about')}
            </Heading>
            <Text fontSize={'sm'} textAlign={'left'}>
              {t('footer.aboutContent')}
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <Heading as="h4" size="md" color={headingColor} mb={4}>
              {t('footer.quickLinks')}
            </Heading>
            <Link href={'#home'} _hover={{ color: linkHoverColor }}>{t('header.home')}</Link>
            <Link href={'#about'} _hover={{ color: linkHoverColor }}>{t('header.about')}</Link>
            <Link href={'#council'} _hover={{ color: linkHoverColor }}>{t('header.council')}</Link>
            <Link href={'#articles'} _hover={{ color: linkHoverColor }}>{t('header.articles')}</Link>
            <Link href={'#contact'} _hover={{ color: linkHoverColor }}>{t('header.contact')}</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <Heading as="h4" size="md" color={headingColor} mb={4}>
              {t('footer.contact')}
            </Heading>
            {/* Link για άμεση κλήση στο τηλέφωνο */}
            <Stack direction={'row'} align={'center'} spacing={2}>
              <Icon as={FaPhone} />
              <Link href="tel:+357 99930057" color={textColor} _hover={{ color: linkHoverColor }}>
                +357 99930057
              </Link>
            </Stack>
            {/* Link για άμεση αποστολή email */}
            <Stack direction={'row'} align={'center'} spacing={2}>
              <Icon as={FaEnvelope} />
              <Link href="mailto:info@asklepiosmed.org" color={textColor} _hover={{ color: linkHoverColor }}>
                info@asklepiosmed.org
              </Link>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
        mt={8}
      >
        <Container
          as={Flex}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={'center'}
          align={'center'}
        >
          <Text textAlign={'center'}>{t('footer.copyright', { year: new Date().getFullYear() })}</Text>
        </Container>
      </Box>

      <Flex justify={'center'} mt={4}>
        <Stack direction={'row'} spacing={6}>
          <Link href={'#'} _hover={{ color: iconHoverColor }}>
            <Icon as={FaFacebook} w={6} h={6} transition="color 0.2s" />
          </Link>
          <Link href={'#'} _hover={{ color: iconHoverColor }}>
            <Icon as={FaTwitter} w={6} h={6} transition="color 0.2s" />
          </Link>
          <Link href={'#'} _hover={{ color: iconHoverColor }}>
            <Icon as={FaLinkedin} w={6} h={6} transition="color 0.2s" />
          </Link>
        </Stack>
      </Flex>
    </Box>
  );
}

export default Footer;
